$(document).ready(function () {

    $('.application-cancel').on('change', function (){

        if ($(this).find('option:selected').text() === 'anderer Grund') {

            $('#reason_for_cancellation_explanation-' + $(this).data('id')).val('');

            $('#reason_for_cancellation_explanation-group-' + $(this).data('id')).removeClass('d-none');
        } else {
            $('#reason_for_cancellation_explanation-group-' + $(this).data('id')).addClass('d-none');

            $('#reason_for_cancellation_explanation-' + $(this).data('id')).val($(this).find('option:selected').text());
        }
    });
});
