$(document).ready(function () {

    $('.operation-cancel').on('change', function (){

        if ($(this).val() === 'anderer Grund') {
            $('#reason_of_cancellation_explanation-group-' + $(this).data('id')).show('fast');
        } else {
            $('#reason_of_cancellation_explanation-group-' + $(this).data('id')).hide('fast');
        }
    });
});
