$(document).ready(() => {

    $('#show-year').on('change', function (){

        if($(this).val() !== 'all'){

            window.location.href = '?year=' + $(this).val();
        }
        else{

            window.location.href = window.location.href.replace(window.location.search,'');
        }
    });
})
