$(document).ready(function () {

    $('.setUserDisplayPermission').on('change', function () {

        let button = $(this);
        let container = button.closest('.container').children('.container-messages');

        $.ajax({
            url: '/userDisplayPermission',
            headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
            type: 'POST',
            data: {
                'object': button.attr('id'),
                'path': window.location.pathname,
                'permission': button[0].checked,
            },
        }).done(function (data) {

            container.empty();

            container.append('<div class="alert alert-success" role="alert">' +
                data.messages[0].text +
                '</div>');

        }).fail(function (jqXHR, textStatus) {

            container.empty();

            container.append('<div class="alert alert-danger" role="alert">' +
                'Etwas ist schiefgegangen. Bitte prüfe Deine Eingaben, versuche es erneut und wenn es dann nicht geht informiere das ZWRD-K unter zwrd-k@bgst.dlrg.de' +
                '</div>');
        });
    });
});
