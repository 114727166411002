import intlTelInput from "intl-tel-input";
import inputTelInputUtils from "../../node_modules/intl-tel-input/build/js/utils";

$(document).ready(() => {
    let input = document.querySelector("#phone");
    if (input != null) {
        intlTelInput(input, {
            hiddenInput: "phone_number",
            initialCountry: "de",
            onlyCountries: ["al", "ad", "at", "by", "be", "ba", "bg", "hr", "cz", "dk",
                "ee", "fo", "fi", "fr", "de", "gi", "gr", "va", "hu", "is", "ie", "it", "lv",
                "li", "lt", "lu", "mk", "mt", "md", "mc", "me", "nl", "no", "pl", "pt", "ro",
                "ru", "sm", "rs", "sk", "si", "es", "se", "ch", "ua", "gb"],
            utlisScript: inputTelInputUtils,
        });
    }
});
