/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

/**
 * Load Bootsrap Toggle
 */
require('bootstrap4-toggle');

require('./intl-tel-input');
require('./select2');
require('../../node_modules/select2/dist/js/select2.min.js');
require('./frequent_questions_index.js');
require('./application_summary.js');
require('./collapsecard');
require('./userDisplayPermission');
require('./operation-cancel');
require('./application-cancel');
require('./qualification');
require('./operation');
