$(document).ready(function () {

    /**
     * This is for collapsible cards.
     */
    // $('.collapse-card .card-header').click(function (e) {
    $('.collapse-card .card-header').on('click', function (e) {
        $(this).siblings('.collapse').collapse('toggle');
        $(this).find('.collapse-icon, .collapse-icon-up, .collapse-icon-down').toggleClass('d-none');

        // should we remember the state?
        let rememberField = $(this).closest('.collapse-card').data('remember-field');

        if (rememberField != null) {
            let oldValue = $(rememberField).val();
            $(rememberField).val((oldValue === '0') ? '1' : '0');
        }
    });

    /**
     * Stop event propagation by click on a button or input to keep card open.
     * Set class 'collapse-me' for a collapse button.
     */
    $('.collapse-card .card-header input:not(.collapse-me), .collapse-card .card-header button:not(.collapse-me)').on('click', function (e) {
        e.stopPropagation();
    });
});
